import React from "react";
import Card from "./sub-components/Card";
import coinImg from "../Assests/btc_logo.png";
import Table from "./sub-components/Table";
import data from "../Assests/Tabledata.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Icon from "./sub-components/icon.json";

export default function Wallet() {
  const [coininfo, setCoininfo] = useState([]);
  const [coinlist, setCoinlist] = useState([]);
  const [bestCoin, setBestCoin] = useState(null);
  const [worstCoin, setWorstCoin] = useState(null);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const uid = localStorage.getItem("id");

  useEffect(() => {
    Promise.all([
      axios.get(process.env.REACT_APP_BASE_URL + `/api/list`),
      axios.get(process.env.REACT_APP_BASE_URL + `/portfolio/coins`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }),
    ]).then(([coininfoRes, walletinfo]) => {
      const coinlist = coininfoRes.data.data;
      const coininfo = walletinfo.data;

      const usercoininfo = coininfo.filter((coin) => coin.uid === uid);
      if (usercoininfo.length > 0) {
        let profit = 0;
        let totalSpent = 0;
        let bestCoin = null;
        let worstCoin = null;

        const icon = Icon;

        usercoininfo.forEach((coin) => {
          const coinData = coinlist.find((c) => c.symbol === coin.symbol);
          if (coinData) {
            const price = coinData.quote.USD.price;

            profit += price * coin.totalQuantity - coin.totalSpent;
            totalSpent += coin.totalSpent;

            const currentProfit = coin.totalQuantity * price - coin.totalSpent;

            if (
              !bestCoin ||
              currentProfit >
                bestCoin.totalQuantity * bestCoin.liveprice -
                  bestCoin.totalSpent
            ) {
              bestCoin = {
                ...coin,
                liveprice: price,
                icon: icon.data[coinData.id].logo,
              };
            }

            if (
              !worstCoin ||
              currentProfit <
                worstCoin.totalQuantity * worstCoin.liveprice -
                  worstCoin.totalSpent
            ) {
              worstCoin = {
                ...coin,
                liveprice: price,
                icon: icon.data[coinData.id].logo,
              };
            }
          }
        });

        setTotalProfit(profit);
        setTotalSpent(totalSpent);
        setCoinlist(coinlist);
        setCoininfo(usercoininfo);
        setBestCoin(bestCoin);
        setWorstCoin(worstCoin);
      }
    });
  }, [uid]);

  useEffect(() => {
    updateCoinInfo(coininfo, coinlist);
  }, [coininfo, coinlist]);

  const updateCoinInfo = (coininfo, coinlist) => {
   
  };

  return (
    <div className="flex w-full flex-col gap-10">
      <div className="overflow-x-auto ">
        <div className="my-8 flex w-fit flex-row justify-center gap-6">
          <Card
            coin={"All Time Profit"}
            coinImg={coinImg}
            tag={"Total Profit"}
            price={totalProfit.toFixed(2)}
            percentage={((totalProfit / totalSpent) * 100).toFixed(2)}
            profit={totalProfit > 0}
          />
          {bestCoin && (
            <Card
              coin={bestCoin.symbol}
              coinImg={bestCoin.icon}
              tag={"Best Performer"}
              price={(
                bestCoin.totalQuantity * bestCoin.liveprice -
                bestCoin.totalSpent
              ).toFixed(2)}
              percentage={(
                ((bestCoin.totalQuantity * bestCoin.liveprice -
                  bestCoin.totalSpent) /
                  bestCoin.totalSpent) *
                100
              ).toFixed(2)}
              profit={
                bestCoin.totalQuantity * bestCoin.liveprice -
                  bestCoin.totalSpent >
                0
              }
            />
          )}
          {worstCoin && (
            <Card
              coin={worstCoin.symbol}
              coinImg={worstCoin.icon}
              tag={"Worst Performer"}
              price={(
                worstCoin.totalQuantity * worstCoin.liveprice -
                worstCoin.totalSpent
              ).toFixed(2)}
              percentage={(
                ((worstCoin.totalQuantity * worstCoin.liveprice -
                  worstCoin.totalSpent) /
                  worstCoin.totalSpent) *
                100
              ).toFixed(2)}
              profit={
                worstCoin.totalQuantity * worstCoin.liveprice -
                  worstCoin.totalSpent >
                0
              }
            />
          )}
        </div>
      </div>
      <div className="flex w-full flex-col gap-4">
        <h3 className="text-[24px] font-normal">Assets</h3>
        <hr className="border-[1.5px] border-[#404040]" />
        <div className="overflow-x-auto px-6 lg:px-0">
          <Table className="" data={data} />
        </div>
      </div>
    </div>
  );
}
