import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import Landing from "layouts/landing";
import Adminauth from "layouts/auth/admin";
import Userauth from "layouts/auth/user";
import ForgotPassword from "views/auth/user/Forgotpassword";
import ResetPassword from "views/auth/user/Resetpassword";
import ForgotAdminPassword from "views/auth/admin/Forgotpassword";
import ResetAdminPassword from "views/auth/admin/Resetpassword";
import UserLayout from "layouts/user";
import Userdetail from "views/admin/userprofile/userdetail/main";
import AddTransaction from "views/admin/userprofile/transaction/index";
import AddInvestment from "views/admin/userprofile/investment/index.jsx";
import ProfitBooking from "views/admin/userprofile/profitbooking/index";
import ProtectAdmin from "utils/ProtectAdmin";
import ProtectUser from "utils/ProtectUser";
import Editprofile from "views/user/profile/Editprofile/index";
import axios from "axios";
import aos from "aos";
import "aos/dist/aos.css";
import AboutUs from "layouts/landing/AboutUs";
import Plans from "layouts/landing/Plans";
import TermsAndConditions from "layouts/landing/TermsAndConditions";
import Form from "./views/user/form/index";
import GettingStarted from "layouts/landing/components/GettingStarted";
import PageNotFound from "layouts/landing/PageNotFound";
import Newsletter from "layouts/newsletter";
import { initGA } from "utils/analytics";
import { Helmet } from "react-helmet"; // Import React Helmet
import Logo from "./assets/img/landings/circle.png"; // Import your logo - adjust the path as needed

const App = () => {
  const [isAuth, setIsAuth] = React.useState(true);

  // Site metadata configuration
  const siteMetadata = {
    title: "Titan Technologies | Empowering Your Financial Future",
    description:
      "TitanTech provides professional investment services, market insights, and portfolio management for investors. Access real-time investment data and expert analysis.",
    image: Logo, // Using the logo image from assets folder
    url: window.location.origin, // Root URL of your website
    twitterHandle: "@titantechmedia",
  };

  useEffect(() => {
    initGA(); // Initialize Google Analytics
  }, []);

  useEffect(() => {
    aos.init({
      duration: 2000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const FormContainer = () => {
    return <div data-tf-live="01JHA8ZSD21SRZ770V69SCKBJH"></div>;
  };

  return (
    <>
      {/* Global Metadata and OpenGraph tags */}
      <Helmet>
        {/* Basic Metadata */}
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <meta
          name="keywords"
          content="investment, stocks, portfolio management, financial services, market analysis, TitanTech"
        />

        {/* OpenGraph Meta Tags for social sharing */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteMetadata.url} />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:description" content={siteMetadata.description} />
        <meta property="og:image" content={siteMetadata.image} />

        {/* Twitter Card data */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={siteMetadata.twitterHandle} />
        <meta name="twitter:title" content={siteMetadata.title} />
        <meta name="twitter:description" content={siteMetadata.description} />
        <meta name="twitter:image" content={siteMetadata.image} />

        {/* Additional metadata */}
        <meta name="author" content="TitanTech" />
        <meta name="language" content="English, Hindi" />
        <link rel="canonical" href={siteMetadata.url} />

        {/* Favicon links - add these files to your public folder */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Helmet>

      <Routes>
        <Route path="auth/admin" element={<Adminauth />} />
        <Route path="auth/user/*" element={<Userauth />} />
        <Route path="auth/user/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="auth/user/resetpassword/:id/:token"
          element={<ResetPassword />}
        />
        <Route
          path="auth/admin/forgotpassword"
          element={<ForgotAdminPassword />}
        />
        <Route
          path="auth/admin/resetpassword/:id/:token"
          element={<ResetAdminPassword />}
        />
        <Route
          path="admin/*"
          element={<ProtectAdmin component={AdminLayout} />}
        />

        {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
        <Route path="/" element={<Landing />} />
        <Route path="/transaction-form" element={<FormContainer />} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/form" element={<Form />} />
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="/term-and-conditions" element={<TermsAndConditions />} />
        {/* <Route path="/plans" element={<Plans />} /> */}
        <Route path="user/*" element={<ProtectUser component={UserLayout} />} />
        <Route
          path="user/editprofile/:id"
          element={<ProtectUser component={Editprofile} />}
        />
        <Route
          path="/admin/userdetail/:id"
          element={<ProtectAdmin component={Userdetail} />}
        />
        <Route
          path="/admin/addtransaction/:id"
          element={<ProtectAdmin component={AddTransaction} />}
        />
        <Route
          path="/admin/addinvestment/:id"
          element={<ProtectAdmin component={AddInvestment} />}
        />
        <Route
          path="/admin/profitbooking/:id"
          element={<ProtectAdmin component={ProfitBooking} />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Analytics />
    </>
  );
};

export default App;
