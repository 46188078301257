import InputField from "components/fields/InputField";

import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import axios from 'axios';
export default function SignIn() {
  const [Password, setPassword] = useState('');
  const {id,token} = useParams();
  const history = useNavigate();

  const Resetpassword =()=>{
    axios.post(process.env.REACT_APP_BASE_URL + `/auth/user/resetpassword/${id}/${token}`, {password: Password}).then((res)=>{
     
        if(res.data.status == 'Success'){
            alert('Password updated successfully');
            history('/auth/user');
        }
        }
    ).catch((err)=>{
        console.log(err);
    }
    )

        


  }

  
  return (
    <div className="relative float-right h-full min-h-screen w-full ">
    <main className={`mx-auto min-h-screen`}>
      <div className="relative flex">
        <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
          <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
           
          <div className=" flex h-full w-full items-center mt-20  justify-center  ">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col justify-center items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Reset Password
        </h4>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Enter New Password"
          id="email"
          type="text"
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          
         
        </div>
        
          <button
          onClick={Resetpassword}
          className="linear mt-2 w-full rounded-xl bg-gd py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Submit
        </button>
     

       
      </div>
    </div>
           
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </main>
  </div>
  );
}
