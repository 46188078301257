import React from "react";
import PortfolioContainer from "./components/PortfolioContainer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Portfolio() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const id = localStorage.getItem("id");
    axios
      .post(
        process.env.REACT_APP_BASE_URL + `/user/getuserbyid`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const user = res.data.find((item) => item._id === id);
        if (user.client) {
          navigate("/user/dashboard");
        }
      });
  }, []);

  return (
    <div className="p-8 text-white">
      <PortfolioContainer />
    </div>
  );
}
