import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Assets/RunningRow.css";

export default function RunningRow({ data }) {
  const [coindata, setCoindata] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h&locale=en",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setCoindata(res.data);
        
      });
  }, []);

  return (
    <div className="container1 absolute bottom-4 flex gap-8 overflow-hidden whitespace-nowrap bg-[#717171] bg-opacity-20 py-3 px-3">
      <div className="slider1 inline-flex">
        {" "}
        {coindata.slice(0, 25).map((value, indx) => (
          <div
            key={indx}
            className="relative mx-4 flex  items-center justify-center gap-8"
          >
            <span className="uppercase">{value.name}</span>
            <span
              className={`${
                value.price_change_percentage_1h_in_currency < 0
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              ${value.current_price}
            </span>
            <div
              className={`${
                value.price_change_percentage_1h_in_currency < 0
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              <span>
                {value.price_change_percentage_1h_in_currency.toFixed(2)}
              </span>
              <span>
                ({value.price_change_percentage_1h_in_currency.toFixed(2)}%)
              </span>
            </div>
            <span className="text-3xl text-[#8180809a]">|</span>
          </div>
        ))}
      </div>
    </div>
  );
}
