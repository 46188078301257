export function formatToINR(number) {
  if (isNaN(number)) return ""; // Ensure the input is a valid number

  let numStr = number.toString();
  let afterPoint = "";

  // Handle decimal part if present
  if (numStr.indexOf(".") > -1) {
    [numStr, afterPoint] = numStr.split(".");
    afterPoint = "." + afterPoint;
  }

  // Format the last three digits
  let lastThree = numStr.slice(-3);
  let otherNumbers = numStr.slice(0, -3);

  if (otherNumbers !== "") {
    lastThree = "," + lastThree;
  }

  // Add commas every 2 digits to the remaining part
  let formatted =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;

  return formatted;
}
export const formatDate = (isoDate) => {
  if (!isoDate) return "Invalid Date"; // Handle null or undefined input

  const dateObj = new Date(isoDate);

  if (isNaN(dateObj.getTime())) return "Invalid Date Format"; // Handle invalid dates

  // Convert to IST (Indian Standard Time, UTC +5:30)
  const options = { day: "2-digit", month: "short", year: "numeric" }; // Human-readable date format
  const formattedDate = dateObj.toLocaleDateString("en-IN", options);
  const formattedTime = dateObj.toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};

export function familiarDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day.toString().padStart(2, "0")}-${month}-${year}`;
}

export const Inr = () => {
  return <>₹</>;
};

export const rupeeWrapper = (amount) => {
  return `₹${amount}`;
};

export function convertDays(inputDays) {
  const daysInYear = 365;
  const daysInMonth = 30;

  if (inputDays > daysInYear) {
    const years = Math.floor(inputDays / daysInYear);
    const remainingDays = inputDays % daysInYear;
    const months = Math.floor(remainingDays / daysInMonth);
    return `${years} year(s) and ${months} month(s)`;
  } else {
    const months = Math.floor(inputDays / daysInMonth);
    const remainingDays = inputDays % daysInMonth;
    return `${months} month and ${remainingDays} day`;
  }
}

export const PasswordValidator = (password) => {
  const minLength = 7;
  const hasNumber = /\d/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

  if (
    password.length >= minLength &&
    hasNumber.test(password) &&
    hasSpecialChar.test(password)
  ) {
    return true;
  }
  return false;
};
export const EmailValidator = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
