import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";
import Img from "../../../assets/img/landings/main.png";
import { Link } from "react-router-dom";
import Landinglogo from "./../../../assets/img/landings/landing.json";
import Glowpink from "./../../../assets/img/landings/glowpink.png";
import RunningRow from "../components/RunningRow";
import data from "../Assets/RunningRow.json";
import Glowblue from "./../../../assets/img/landings/glowblue.png";
import { duration } from "moment";

export default function MainSection() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="1500"
      data-aos-easing="ease-in-out"
      className="relative flex h-full w-full flex-col items-center md:flex-row"
    >
      <img
        src={Glowpink}
        alt="Glowpink"
        className="absolute top-0 right-[8rem]"
        style={{
          width: "750px",
          height: "750px",
          zIndex: "-3",
        }}
      />
      <img
        src={Glowpink}
        alt="Glowpink"
        className="absolute bottom-0 left-0"
        style={{
          width: "750px",
          height: "750px",
          zIndex: "-3",
        }}
      />
      <div className="text col gap-8 p-12 md:w-1/2 md:p-24">
        <h1
          className="center text-3xl leading-tight md:text-5xl md:leading-none"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1500"
          data-aos-easing="ease-in-out"
          style={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "48px",
            lineHeight: "normal",
          }}
        >
          <span
            style={{
              background:
                "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Empowering Your
          </span>
          <br />
          Financial Future.
        </h1>
        <span
          className="text-app_gray text-xl leading-8"
          style={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "22px",
            color: "#ACACAC",
          }}
        >
          Explore cutting-edge crypto investment opportunities with personalized
          portfolio management.
        </span>
        <div data-aos="fade-left" className="flex justify-between">
          <a href="https://onboarding.titantechnologies.in" target="_self">
            <button
              type="button"
              className="w-fit rounded-lg bg-gradient-to-l from-[#9b51e0] to-[#f78da7] px-7 py-2.5 text-xl font-semibold text-white hover:from-[#f78da7] hover:to-[#9b51e0]"
            >
              Get Started
            </button>
          </a>
          {/* <button className="mx-5 rounded bg-white px-7 py-2.5  text-xl text-[#000000]">
            Fill form
          </button> */}
        </div>
      </div>
      <div className="col center relative gap-4 md:w-1/2">
        <dotlottie-player
          src="https://lottie.host/1156c27a-a84a-457f-8eff-3aa89f423092/7oW6ZiF4QG.json"
          background="transparent"
          speed="2"
          style={{ width: "42em", height: "42em" }}
          loop
          autoplay
        ></dotlottie-player>
      </div>
      <RunningRow data={data} />
    </section>
  );
}
