import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-LG00JSYW1P";

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = () => {
  ReactGA.send("pageview");
};
