import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@sweetalert2/theme-dark/dark.css";
import "sweetalert2/dist/sweetalert2.min.css";
import App from "./App";

// Analytics Configuration
const ANALYTICS_CONFIG = {
  googleAnalytics: {
    measurementId: "G-P4FZTW5ZHM",
    src: "https://www.googletagmanager.com/gtag/js",
  },
  typeform: {
    src: "//embed.typeform.com/next/embed.js",
  },
};

// Function to load external scripts dynamically
const loadScript = (src, options = {}) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;

    // Set additional options
    Object.keys(options).forEach((key) => {
      script[key] = options[key];
    });

    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));

    // Append to appropriate parent element
    const parent = options.parent || document.head;
    parent.appendChild(script);
  });
};

// Initialize Google Analytics
const initGoogleAnalytics = () => {
  const { measurementId, src } = ANALYTICS_CONFIG.googleAnalytics;

  // Load GA script
  loadScript(`${src}?id=${measurementId}`)
    .then(() => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", measurementId);
    })
    .catch((error) => {
      console.error("Google Analytics script load failed:", error);
    });
};

// Load all external scripts
const initScripts = async () => {
  try {
    // Load Typeform script
    await loadScript(ANALYTICS_CONFIG.typeform.src, {
      parent: document.body,
    });

    // Initialize Google Analytics
    initGoogleAnalytics();
  } catch (error) {
    console.error("Script initialization error:", error);
  }
};

// Initialize scripts before rendering
initScripts();

// Render React App
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <ToastContainer stacked theme="dark" />
    </BrowserRouter>
  </React.StrictMode>
);
