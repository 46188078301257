import PieChart from "components/charts/PieChart";
import Card from "components/card";
import { useState, useEffect } from "react";

const PieChartCard = (props) => {
  const { totalinvested, investment } = props;
 
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [hasInvestments, setHasInvestments] = useState(false);
  const colors = [
    "#9332DA",
    "#13A8EB",
    "#9792E3",
    "#FFC145",
    "#AF4D98",
    "#9C3848",
    "#AF4D98",
    "#A691DA",
    "#353981",
    "#DB43DB",
    "#EFF4FB",
  ];

  useEffect(() => {
    if (investment && investment.length > 0) {
      // Sort investments by date
      const sortedInvestments = [...investment].sort(
        (a, b) => new Date(b.investdate) - new Date(a.investdate)
      );

      // Take the first five investments
      const topFiveInvestments = sortedInvestments.slice(0, 4);

      // Group the remaining investments as 'Others'
      const othersInvestments = sortedInvestments.slice(4);
      const othersTotal = othersInvestments.reduce(
        (acc, cur) => acc + cur.investamount,
        0
      );

      // Calculate total investment for each coin in top five
      const coinInvestments = {};
      topFiveInvestments.forEach((item) => {
        if (coinInvestments[item.coinname]) {
          coinInvestments[item.coinname] += item.investamount;
        } else {
          coinInvestments[item.coinname] = item.investamount;
        }
      });

      // Calculate percentages
      const totalInvestmentAmount =
        othersTotal +
        Object.values(coinInvestments).reduce((acc, cur) => acc + cur, 0);

      const percentages = {};
      Object.keys(coinInvestments).forEach(
        (coin) =>
          (percentages[coin] =
            (coinInvestments[coin] / totalInvestmentAmount) * 100)
      );

      // Add Others percentage separately
      if (othersTotal > 0) {
        percentages["Others"] = (othersTotal / totalInvestmentAmount) * 100;
      }

      // Sort the coins based on their percentages in descending order, excluding Others
      const sortedCoins = Object.keys(percentages)
        .filter((coin) => coin !== "Others")
        .sort((a, b) => percentages[b] - percentages[a]);

      // Add Others at the end if it exists
      if (percentages["Others"]) {
        sortedCoins.push("Others");
      }

      // Set the series and labels
      setSeries(sortedCoins.map((coin) => percentages[coin]));
      setLabels(
        sortedCoins.map((coin) => `${coin} (${percentages[coin].toFixed(2)}%)`)
      );
      setHasInvestments(true);
    } else {
      setHasInvestments(false);
    }
  }, [investment]);

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Assets Allocation
          </h4>
        </div>
      </div>

      <div className="flex h-[400px] w-full md:mt-6 md:h-[300px]">
        {hasInvestments ? (
          <div className="flex w-full flex-col items-center justify-center md:flex-row">
            <div className="w-1/2">
              <PieChart
                options={{
                  labels: labels,
                  colors: colors,
                  chart: {
                    width: "100%",
                  },
                  states: {
                    hover: {
                      filter: {
                        type: "none",
                      },
                    },
                  },
                  legend: {
                    show: false,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  hover: { mode: null },
                  plotOptions: {
                    pie: {
                      donut: {
                        expandOnClick: true,
                        labels: {
                          show: false,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: colors,
                  },
                  tooltip: {
                    enabled: true,
                    theme: "dark",
                    style: {
                      fontSize: "12px",
                      fontFamily: undefined,
                      backgroundColor: "#973646",
                    },
                  },
                }}
                series={series}
              />
            </div>
            <div className="flex w-1/2 flex-col justify-center">
              <ul className="text-md list-none font-bold text-navy-700 dark:text-white">
                {labels.map((label, index) => (
                  <li key={index} className="mb-2 flex items-center">
                    <span
                      className="mr-2 inline-block h-3 w-3 rounded-full"
                      style={{ backgroundColor: colors[index] }}
                    ></span>
                    {label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <p className="text-lg font-bold text-gray-500 dark:text-gray-400">
            No investments made yet.
          </p>
        )}
      </div>
    </Card>
  );
};

export default PieChartCard;
