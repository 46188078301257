import InputField from "components/fields/InputField";

import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dash from "./../../../assets/img/landings/dash.png";
import Logo from "./../../../assets/img/landings/Vector.png";
import Glowpurple from "./../../../assets/img/landings/glowblue.png";
import "./form.css";
import "./Assests/auth.css";
import { GoEyeClosed, GoEye } from "react-icons/go";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import axios from 'axios';
export default function SignIn(props) {
  const { setModal } = props;
  const [email, setEmail] = useState("");
  const history = useNavigate();

  // const handleforgotpassword = () => {
  //   console.log(email);

  //   axios
  //     .post(process.env.REACT_APP_BASE_URL + "/auth/user/forgotpassword", {
  //       email: email,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.Status == "Success") {
  //         toast.success("Password reset link has been sent to your email");
  //         history("/auth/user/login");
  //       } else {
  //         toast.error(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data);
  //       console.log(err);
  //     });
  // };
  const handleforgotpassword = async () => {


    let res = axios.post(
      process.env.REACT_APP_BASE_URL + "/auth/user/forgotpassword",
      {
        email: email,
      }
    );
    // toast.promise(res, {
    //   pending: "Checking the email",
    //   success: () => {
    //     history("/auth/user/login");
    //     return "Password reset link has been sent to your email";
    //   },

    //   error: (err) => {
    //     return err.response.data;
    //   },
    // });
    toast.promise(res, {
      pending: "Checking the email",
      success: {
        render() {
          history("/auth/user/login");
          return "Password reset link has been sent to your email";
        },
      },
      error: {
        render({ data }) {
          return data.response.data;
        },
      },
    });
    // .then((res) => {
    //   console.log(res);
    //   if (res.data.Status == "Success") {
    //     toast.success("Password reset link has been sent to your email");
    //     history("/auth/user/login");
    //   } else {
    //     toast.error(res.data);
    //   }
    // })
    // .catch((err) => {
    //   toast.error(err.response.data);
    //   console.log(err);
    // });
  };

  return (
    // <div className=" relative flex flex-col w-full p-10">
    //                     {/*header*/}
    //                     <div className="flex items-start justify-between pt-5 pb-5 border-b border-solid rounded-t">
    //                       <h3 className="text-3xl font-semibold">Forgot Password</h3>
    //                     </div>
    //                     {/*body*/}
    //                     <div className="relative ">
    //                       <div
    //                         className="grid w-full grid-cols-1  md:grid-cols-1 gap-7 mt-7"
    //                         style={{
    //                           color: "#ACACAC",
    //                         }}
    //                       >

    //                         <div>
    //                           <p className="text-base font-medium leading-none text-white-800">
    //                             Email
    //                           </p>
    //                           <InputField
    //                             className="w-full p-3 mt-4 border border-white-300 rounded outline-none focus:bg-white-50"
    //                             onChange={(e) => setEmail(e.target.value)}
    //                             placeholder="Enter Your Email"
    //                           />
    //                         </div>

    //                       </div>
    //                     </div>
    //                     {/*footer*/}
    //                     <div className="flex items-center justify-between mt-10 rounded-b">
    //                       <button
    //                         className="text-[#F9F9F9]-500 flex flex-row gap-2 font-bold uppercase px-6 py-2 text-sm outline-nonemr-1 mb-1 ease-linear transition-all duration-150"
    //                         type="button"
    //                         onClick={() => setModal(false)}
    //                       >
    //                         <svg
    //                           width="20"
    //                           height="20"
    //                           viewBox="0 0 100 100"
    //                           xmlns="http://www.w3.org/2000/svg"
    //                         >
    //                           <line
    //                             x1="10"
    //                             y1="10"
    //                             x2="90"
    //                             y2="90"
    //                             stroke="#F9F9F9"
    //                             strokeWidth="10"
    //                           />
    //                           <line
    //                             x1="90"
    //                             y1="10"
    //                             x2="10"
    //                             y2="90"
    //                             stroke="#F9F9F9"
    //                             strokeWidth="10"
    //                           />
    //                         </svg>{" "}
    //                         Cancel
    //                       </button>
    //                       <button
    //                         className="text-white  bg-gd active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    //                         type="button"
    //                         onClick={handleforgotpassword}

    //                       >
    //                         Submit
    //                       </button>
    //                     </div>
    //                   </div>
    <>
      <img
        src={Glowpurple}
        alt=""
        draggable="false"
        className="absolute h-1/2 w-1/2  "
        style={{
          zIndex: 20,
          left: "0",
          top: "50",
        }}
      />
      <img
        src={Glowpurple}
        alt=""
        draggable="false"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "50%",
          zIndex: -99,
        }}
        className="absolute bottom-0 right-0 w-full"
      />

      <div className="relative hidden h-full w-full p-16 lg:block">
        <img src={Logo} alt="" className="ml-10" />
      </div>

      <div
        className="relative !z-50  flex h-fit w-full items-center justify-center py-8 px-6 md:mx-auto md:w-1/2 md:max-w-md lg:max-w-full lg:px-16 xl:w-3/5 xl:px-12"
        style={{
          boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
          backdropFilter: "blur( 25px )",
          WebkitBackdropFilter: "blur( 25px )",
          borderRadius: "10px",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
          zIndex: 2,
        }}
      >
        <div className="w-full  rounded-lg  p-8 backdrop-blur-lg">
          <h1 className="text-gd  w-fit text-3xl font-bold leading-tight text-white md:text-[29px]">
            Forgot Password
          </h1>
          <div className="mt-6 flex flex-col  !text-[#ACACAC]">
            <div className="flex flex-col justify-start gap-3">
              <label htmlFor="email">Email</label>
              <input
                extra="mb-3"
                placeholder="Enter your Email"
                id="email"
                required
                className="rounded-md border-[.7px] border-[#acacac] bg-[#ffffff00] px-4 py-3 outline-none placeholder:text-[#acacac] "
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              // type="submit"
              className="hover:from-from-[#F78DA7] mt-6 block w-full rounded-lg bg-gradient-to-r from-[#F78DA7] to-[#9B51E0] px-4 py-3 font-semibold text-white hover:to-[#9B51E0] focus:from-[#fe89a6] focus:to-[#9444de]"
              // onClick={handleSignIn}
              onClick={handleforgotpassword}
            >
              Reset Password
            </button>
          </div>
          {/* <hr className="my-6 w-full border-gray-300" /> */}
          <div className="flex w-full items-center py-4 text-white">
            <div className="h-[.05px] w-full border-spacing-20 border-[.05px]  border-t border-dashed border-[#525252]  "></div>
            <div className="px-4 pb-1">or</div>
            <div className="h-[.05px] w-full border-[.05px]  border-t border-dashed border-[#525252]  "></div>
          </div>
          <button
            type="button"
            className="flex w-full items-center justify-center rounded-lg border bg-[#010101] px-4 py-3 font-semibold text-white  transition-all duration-300 hover:bg-white hover:text-[#010101]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 48 48"
            >
              <path
                fill="#4285F4"
                d="M48 24c0-1.31-.1-2.57-.27-3.79H24v7.58h13.65c-.59 3.11-2.47 5.74-5.28 7.42v6.1h8.51c4.98-4.59 7.87-11.38 7.87-19.31z"
              />
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.92-2.15 15.89-5.81l-8.51-6.1c-2.37 1.6-5.37 2.55-7.38 2.55-5.61 0-10.37-3.8-12.07-8.87H3.38v5.66C7.36 42.65 15.09 48 24 48z"
              />
              <path
                fill="#FBBC05"
                d="M11.93 28.37c-.55-1.31-.87-2.72-.87-4.37s.32-3.06.87-4.37V14.97H3.38C1.23 19.06 0 23.88 0 28.37s1.23 9.31 3.38 13.4l8.55-6.1z"
              />
              <path
                fill="#EA4335"
                d="M24 9.52c3.26 0 6.17 1.11 8.48 2.91l6.38-6.38C34.02 2.77 29.32 0 24 0 15.09 0 7.36 5.35 3.38 13.4l8.55 6.1C13.63 13.32 18.39 9.52 24 9.52z"
              />
            </svg>
            <span className="ml-4">Continue with Google</span>
          </button>
          <p className="mt-8 text-gray-300">
            Already have a account?{" "}
            <Link
              to="/auth/user/login"
              className="font-semibold text-[#F78DA7] hover:underline"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
