import React from "react";
import Header from "./components/Header";
import FooterSection from "./sections/FooterSection";
import FeaturesSection from "./sections/FeaturesSection";
import Certificate from "./sections/Certificate";
import gloworange from "../../assets/img/landings/gloworange.png";
import glowpink from "../../assets/img/landings/glowpink.png";
export default function AboutUs() {
  return (
    <div className="flex flex-col items-center justify-center text-white">
      <Header onAboutUs={true} />
      <Certificate gd={true} />
      <div className="relative mt-10 rounded-[10px] bg-[#ffffff]/10 p-6  text-white md:max-w-6xl">
        <img
          src={gloworange}
          className="glowMovingTL pulse absolute left-[-3rem] h-[42rem] opacity-60"
          alt=""
        />
        <img
          src={glowpink}
          className="movePurple pulse absolute left-[-3rem] h-[42rem] opacity-60"
          alt=""
        />
        <h1 className="mb-6 text-center text-3xl font-bold">ABOUT US</h1>

        <section className="z-10 mb-8">
          <h2 className="text-xl font-semibold">
            Your Trusted Partner in Crypto Investments & Consultancy
          </h2>
          <p className="mt-2">
            At <strong>TITANTECH INVESTMENTS</strong>, we are a premier crypto
            portfolio management and consultancy firm, committed to delivering
            exceptional financial growth through strategic cryptocurrency
            investments.
          </p>
          <p>
            We provide individuals and businesses with a secure, high-yield
            investment ecosystem, ensuring sustainable returns while
            safeguarding capital with 100% security measures.
          </p>
          <p>
            With a dual-service model, we operate as both an investment
            management firm and a consultancy, offering expert guidance and
            hands-free portfolio management.
          </p>
          <p>
            Our partnership with CoinDCX, a globally trusted crypto exchange,
            further strengthens our commitment to secure, transparent, and
            regulated investment operations.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-bold">Our Services</h2>

          <h3 className="text-xl font-semibold">
            1. Crypto Portfolio Management
          </h3>
          <ul className=" pl-6">
            <li>
              ✔ Diversified Crypto Investments – Funds are allocated across
              multiple digital assets for optimal risk management.
            </li>
            <li>
              ✔ Monthly Returns & Passive Income – Earn between 4% to 20% per
              month, based on market performance and strategy.
            </li>
            <li>
              ✔ 100% Security of Capital – Our investment strategies focus on
              capital preservation while maximizing profits.
            </li>
            <li>
              ✔ Exclusive Investor Dashboard – A real-time dashboard provides a
              complete overview of your investment growth and earnings.
            </li>
            <li>
              ✔ Seamless & Regulated Transactions – All investments and
              withdrawals are processed through secure, compliant exchanges.
            </li>
          </ul>

          <h3 className="mt-4 text-xl font-semibold">
            2. Crypto Investment Consultancy
          </h3>
          <ul className=" pl-6">
            <li>
              ✔ Market Analysis & Research – Data-driven insights into emerging
              crypto trends and opportunities.
            </li>
            <li>
              ✔ Risk Management Strategies – Tailored investment approaches to
              minimize downside risks while optimizing gains.
            </li>
            <li>
              ✔ Personalized Investment Planning – Custom portfolio allocation
              based on risk appetite and financial goals.
            </li>
            <li>
              ✔ Staking & DeFi Yield Advisory – Helping investors leverage
              decentralized finance (DeFi) protocols for additional income.
            </li>
            <li>
              ✔ Regulatory Compliance Guidance – Ensuring fully legal and secure
              investment structures.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-bold">How We Work</h2>
          <ol className="list-decimal pl-6">
            <li>
              <strong>Secure & Hassle-Free Onboarding:</strong> Contact us via
              WhatsApp or our website and submit your Government ID and
              Permanent Account Number for verification.
            </li>
            <li>
              <strong>Choose Your Investment Model:</strong> Fully Managed
              Portfolio or Consultancy Service.
            </li>
            <li>
              <strong>Track & Grow Your Wealth:</strong> Gain access to your
              personalized investment dashboard.
            </li>
            <li>
              <strong>Secure Monthly Payouts & Long-Term Growth:</strong>{" "}
              Receive consistent monthly earnings directly into your registered
              account.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="mb-4 text-2xl font-bold">
            Why TITANTECH INVESTMENTS?
          </h2>
          <ul className=" pl-6">
            <li>
              ✅ A Premier Crypto Investment Firm – Combining portfolio
              management and consultancy for holistic crypto wealth creation.
            </li>
            <li>
              ✅ 100% Security of Capital – Funds are managed through regulated
              exchanges and secure financial models.
            </li>
            <li>
              ✅ Expert-Led Strategies – Market-driven investment decisions
              ensure optimal risk-to-reward ratios.
            </li>
            <li>
              ✅ CoinDCX Partnership – Enabling fast, secure, and legally
              compliant transactions.
            </li>
            <li>
              ✅ Exclusive Investor Dashboard – A sophisticated real-time
              tracking tool for full transparency.
            </li>
            <li>
              ✅ Future-Ready Investment Solutions – AI-powered trading
              strategies, DeFi & staking integrations, and expansion into
              digital asset tokenization.
            </li>
          </ul>
        </section>

        <section className="mt-6 text-center">
          <p className="font-semibold">
            📩 Email Us:{" "}
            <a
              href="mailto:help@titantechnologies.in"
              className="text-blue-600"
            >
              help@titantechnologies.in
            </a>
          </p>
          <p className="font-semibold">
            📞 Call/WhatsApp:{" "}
            <a href="tel:+919329104725" className="text-blue-600">
              +91-9329104725
            </a>
          </p>
          <p className="font-semibold">
            🌐 Visit Us:{" "}
            <a
              href="https://titantechnologies.in"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600"
            >
              titantechnologies.in
            </a>
          </p>
        </section>
      </div>
      <FooterSection />
    </div>
  );
}
