import React, { useRef, useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "./sub-components/icon.json";
export default function Modal({ closePopUp, onTransactionSuccess }) {

  const [selected, setSelected] = useState("buy");
  const [coin, setCoin] = useState();
  const [coindata, setCoindata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [pricePerCoin, setPricePerCoin] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const id = localStorage.getItem("id");
  const modelRef = useRef();

  const closeModal = (e) => {
    if (modelRef.current === e.target) {
      closePopUp();
    }
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + `/api/list`).then((res) => {
 
      setCoindata(res.data.data);
      setLoading(false);
    });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectCoin = (selectedCoin) => {
    setCoin(selectedCoin.symbol);
    console.log(selectedCoin.quote);
    setPricePerCoin(selectedCoin.quote.USD.price);
  };

  const handleAddTransaction = async () => {
   
    if(quantity < 0){
      return toast.error("Quantity cannot be negative");
    }
    const transaction = {
      uid: id,
      coinId : coindata.find((c) => c.symbol === coin)?.id,
      type: selected,
      coinName: coin,
      quantity: parseFloat(quantity),
      coinSymbol: coindata.find((c) => c.symbol === coin)?.symbol, // Using optional chaining to avoid errors if coindata.find returns undefined
      pricePerCoin: parseFloat(pricePerCoin).toFixed(2),
      totalSpent: (parseFloat(quantity) * parseFloat(pricePerCoin)).toFixed(2),
    };
  
    const transactionPromise = axios.post(
      `${process.env.REACT_APP_BASE_URL}/portfolio/transaction`,
      transaction
    );
  
    try {
      const res = await toast.promise(transactionPromise, {
        pending: "Adding transaction",
        success: {
          render({ data }) {
            closePopUp();
            return `Transaction completed successfully`;
          },
        },
        error: {
          render({ data }) {
            return `Failed transaction: ${
              data?.response?.data?.message || data.message
            }`;
          },
        },
      });
  
  
      onTransactionSuccess();
      closePopUp();
    } catch (err) {
      console.error(err);
    }
  };
  
  const filteredCoins = coindata
    .filter((coin) =>
      coin.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, 8);

  return (
    <div
      ref={modelRef}
      onClick={closeModal}
      className="fixed inset-0 !z-50 flex h-full w-full items-center justify-center bg-[#252525] bg-opacity-80"
    >
      {!coin ? (
        <div className="flex min-h-[400px] w-fit min-w-[400px] flex-col gap-6 rounded-xl border-[1px] border-[#404040] bg-[#222] py-16 px-12">
          <div className="flex w-full items-center justify-between">
            <h2 className="text-[24px] font-bold">Select Coin</h2>
            <button onClick={closePopUp}>
              <IoMdClose className="text-[24px] text-[#DDDDDD]" />
            </button>
          </div>
          <input
            type="text"
            placeholder="Search Coin"
            className="w-[400px] rounded-md bg-[#404040] py-3 px-4 outline-none"
            value={searchTerm}
            onChange={handleSearch}
          />
          <ul>
            {filteredCoins.map((coin) => (
              <li
                key={coin.id}
                className="mb-2 flex w-full cursor-pointer items-center gap-3"
                onClick={() => handleSelectCoin(coin)}
              >
                <img
                  src={Icon.data[coin.id].logo}
                  className="h-8 w-8 rounded-full"
                  alt={coin.name}
                />
                <span className="flex items-baseline gap-3">
                  {coin.name}{" "}
                  <span className="text-[#A1A1A1]">{coin.symbol}</span>
                </span>
                <IoIosArrowForward className="ml-auto" />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="flex min-h-[400px] w-fit min-w-[400px] flex-col gap-6 rounded-xl border-[1px] border-[#404040] bg-[#222] py-16 px-12">
          <h2 className="text-[24px] font-bold">Add a transaction</h2>
          <div className="slider flex w-full rounded-md bg-[#404040] p-[3px] text-[#ACACAC]">
            <button
              onClick={() => setSelected("buy")}
              className={`w-full rounded-md transition-all duration-150 ${
                selected === "buy" ? "bg-white py-[6px] text-[#252525]" : ""
              }`}
            >
              Buy
            </button>
            <button
              onClick={() => setSelected("sell")}
              className={`w-full rounded-md transition-all duration-150 ${
                selected === "sell" ? "bg-white py-[6px] text-[#252525]" : ""
              }`}
            >
              Sell
            </button>
          </div>
          <div>
            <select
              value={coin}
              onChange={(e) =>
                handleSelectCoin(
                  coindata.find((c) => c.symbol === e.target.value)
                )
              }
              className="w-full rounded-md border-[1px] border-[#404040] bg-[#ffffff00] px-4 py-2 text-white outline-none"
            >
              {coindata.map((coin) => (
                <option
                  key={coin.id}
                  value={coin.symbol}
                  className="bg-[#ffffff00]"
                >
                  {coin.name} ({coin.symbol.toUpperCase()})
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-2">
            <div className="flex w-full flex-col gap-2">
              <p>Quantity</p>
              <input
                className="rounded-md border-[1px] border-[#404040] bg-[#ffffff00] px-4 py-2 text-white outline-none"
                type="number"
                min={0}

                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="flex w-full flex-col gap-2">
              <p>Price Per Coin</p>
              <input
                className="rounded-md border-[1px] border-[#404040] bg-[#ffffff00] px-4 py-2 text-white outline-none"
                type="number"
                min={0}
                value={
                  //show 2 decimal places
                  pricePerCoin.toFixed(2)
                }
                onChange={(e) => setPricePerCoin(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1 rounded-md border-[1px]  border-[#404040] px-6 py-3">
            <p>Total Spent</p>
            <p>
              $
              {
                //show 2 decimal places
                (quantity * pricePerCoin).toFixed(2)
              }
            </p>
          </div>
          <button
            className="bg-gd flex w-full items-center justify-center gap-1 rounded-md px-3 py-3 text-[14px]"
            onClick={handleAddTransaction}
          >
            Add a transaction
          </button>
        </div>
      )}
    </div>
  );
}