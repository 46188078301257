import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Progress from "components/progress";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { rupeeWrapper } from "utils/utility";
import { formatToINR } from "utils/utility";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
const DevelopmentTable = (props) => {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = tableInstance;

  const deleteTransaction = async (tx) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this transaction?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it! ☑️",
      cancelButtonText: "Cancel✖️",
      backdrop: true, // Ensures overlay is dark
      color: "#fff", // Makes text white
      background: "#121212", // Dark background manually
      customClass: {
        popup: "swal2-dark",
      },
    });
    if (result.isConfirmed) {
      const deletePromise = axios.delete(
        `${process.env.REACT_APP_BASE_URL}/transaction/deletetransaction/${tx._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      toast.promise(deletePromise, {
        pending: "Deleting transaction...",
        success: "Transaction deleted successfully! 🎉",
        error: "Failed to delete transaction. Please try again. 🚨",
      });

      try {
        const response = await deletePromise;
        // window.location.reload();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className={"h-full w-full p-4"}>
      <div className="relative mt-5 flex items-center justify-between">
        <div className="text-3xl font-bold text-navy-700 dark:text-white">
          Transactions
        </div>
      </div>

      <div className="h-full overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="mt-8 h-max w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-32 pb-[10px] text-start dark:!border-navy-700 "
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  No data found
                </td>
              </tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "NAME") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.Header === "AMOUNT") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {rupeeWrapper(formatToINR(cell.value))}
                          </p>
                        );
                      } else if (cell.column.Header === "TYPE") {
                        data = (
                          <p
                            className={`text-sm font-bold ${
                              cell.value === "SEND"
                                ? "text-red-700"
                                : "text-green-700"
                            }`}
                          >
                            {cell.value}
                          </p>
                        );
                      } else if (cell.column.Header === "PAYMENT METHOD") {
                        data = (
                          <div className="flex items-center gap-3">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "DATE (MM/DD/YYYY)") {
                        data = (
                          <div className="flex items-center gap-3">
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {new Date(cell.value).toLocaleDateString()}
                            </p>
                          </div>
                        );
                      } else {
                        data = (
                          <div
                            onClick={() => deleteTransaction(cell.row.original)}
                            className=" ml-2 flex w-fit rounded bg-red-400 p-1.5 text-[24px] text-[#000000]"
                          >
                            <MdDelete />
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <FaArrowCircleLeft
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="h-6 w-6 cursor-pointer"
        />
        <FaArrowCircleRight
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="h-6 w-6 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default DevelopmentTable;
