import React, { useEffect, useState } from "react";
import Logo from "../../assets/newsletter.png";
import favicon from "../../assets/favicon.png";

import { Helmet } from "react-helmet"; // You'll need to install this package

const Newsletter = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const NEWSLETTER_URLS = {
    english:
      "https://titantech-newsletter.notion.site/ebd/194cf9649d9080daa55feec27f744df2",
    hindi:
      "https://titantech-newsletter.notion.site/ebd/1b2cf9649d908085986ecd9125d4ca8c",
  };

  // Site metadata configuration with absolute URL for images
  const siteMetadata = {
    title: "Daily Crypto analysis | Titan Technologies",
    description:
      "Subscribe to TitanTech's newsletter for the latest market insights, stock analysis, and investment opportunities in English and Hindi.",
    // Using newsletter.png for the Twitter card and social sharing
    image: process.env.REACT_APP_BASE_URL
      ? `${process.env.REACT_APP_BASE_URL}/assets/newsletter.png`
      : `${window.location.protocol}//${window.location.host}/assets/newsletter.png`,
    url: window.location.href, // Dynamically get the current URL
    twitterHandle: "@titantechmedia", // Replace with actual Twitter handle
  };

  useEffect(() => {
    // Add meta viewport tag to ensure proper responsiveness
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    document.head.appendChild(meta);

    // Clean up function
    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    setShowPopup(false);
  };

  return (
    <div className="fixed inset-0 m-0 h-full w-full overflow-hidden p-0">
      {/* Helmet for adding metadata and OpenGraph tags */}
      <Helmet>
        {/* Basic Metadata */}
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <meta
          name="keywords"
          content="newsletter, market insights, TitanTech, investment, stocks, finance"
        />

        {/* OpenGraph Meta Tags for social sharing */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteMetadata.url} />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:description" content={siteMetadata.description} />
        <meta property="og:image" content={siteMetadata.image} />

        {/* Twitter Card data - updated for better compatibility */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={siteMetadata.twitterHandle} />
        <meta name="twitter:creator" content={siteMetadata.twitterHandle} />
        <meta name="twitter:title" content={siteMetadata.title} />
        <meta name="twitter:description" content={siteMetadata.description} />
        <meta name="twitter:image" content={siteMetadata.image} />
        <meta name="twitter:image:alt" content="TitanTech Newsletter" />

        {/* Additional metadata */}
        <meta name="author" content="TitanTech" />
        <meta name="language" content="English, Hindi" />
        <link rel="canonical" href={siteMetadata.url} />
      </Helmet>

      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#000001]/90">
          <div className="relative mx-4 w-full max-w-md rounded-[10px] bg-[#ffffff]/10 p-6 text-white backdrop-blur-sm">
            {/* Glow effects */}
            <div className="absolute left-[-3rem] h-[42rem] opacity-60">
              {/* This would be your gloworange image */}
              <div className="absolute h-[300px] w-[300px] rounded-full bg-[#f78da7]/30 blur-3xl"></div>
            </div>
            <div className="absolute right-[-3rem] h-[42rem] opacity-60">
              {/* This would be your glowpink image */}
              <div className="absolute h-[300px] w-[300px] rounded-full bg-[#9b51e0]/30 blur-3xl"></div>
            </div>

            {/* Content */}
            <div className="relative z-10">
              {/* Logo container */}
              <div className="mb-6 flex justify-center">
                <div className="flex h-40 w-40 items-center justify-center rounded-full bg-white/10 p-3">
                  {/* Replace with your actual logo */}
                  <img
                    src={favicon}
                    alt="TitanTech Logo"
                    width={300}
                    height={300}
                  />
                </div>
              </div>

              <h1 className="mb-6 text-center text-3xl font-bold">
                NEWSLETTER
              </h1>

              <h2 className="mb-4 text-center text-xl font-semibold">
                Choose Your Preferred Language
              </h2>

              <div className="mt-8 flex flex-col gap-4">
                <button
                  onClick={() => selectLanguage("english")}
                  type="button"
                  className="w-full rounded-md bg-gradient-to-r from-[#9b51e0] to-[#f78da7] px-5 py-3 text-center text-sm font-semibold text-white transition-all hover:from-[#f78da7] hover:to-[#9b51e0]"
                  style={{
                    background:
                      "linear-gradient(to left, #9b51e0, #c655cc, #e162ba, #f076ad, #f78da7)",
                    letterSpacing: "0.1em",
                  }}
                >
                  ENGLISH
                </button>

                <button
                  onClick={() => selectLanguage("hindi")}
                  type="button"
                  className="bg-transparent w-full rounded-md border border-white/30 px-5 py-3 text-center text-sm font-semibold text-white transition-all hover:bg-white/10"
                  style={{
                    letterSpacing: "0.1em",
                  }}
                >
                  हिंदी (HINDI)
                </button>
              </div>

              <div className="mt-6 text-center text-sm text-white/60">
                Select your language to view our latest reports and updates
              </div>
            </div>
          </div>
        </div>
      )}

      {!showPopup && selectedLanguage && (
        <iframe
          src={NEWSLETTER_URLS[selectedLanguage]}
          className="m-0 block h-full w-full border-0 p-0"
          allowFullScreen
          title={`TitanTech Newsletter - ${selectedLanguage}`}
        />
      )}
    </div>
  );
};

export default Newsletter;
