import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from "axios";
import glowCyan from "../Assets/glowCyan.png";
import glowOrange from "../../../assets/img/landings/gloworange.png";
import glowpink from "../../../assets/img/landings/glowpink.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export default function ContactUs2() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone,setPhone] = useState();
  const [budget, setBudget] = useState("");
  const [location, setLocation] = useState("");
  const [message,setMessage]=useState('')
  const location1 = useLocation();

  useEffect(() => {
    if (location1.hash === "#contact-us-custom-plan") {
      setBudget("Custom Plan");
    }
  }, [location1.hash]);


  const handleMail = () => {
    if (!email || !name || !phone || !budget || !message) {
      toast("Please fill all the fields");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Email is not valid!");
      return;
    }
  

    let res = axios.post(process.env.REACT_APP_BASE_URL + "/send-email", {
      email,
      name,
      phone,
      budget,
      message,
    });

    toast.promise(res, {
      pending: "Sending message",
      success: "Message sent successfully",
      error: {
        render({ data }) {
          return `Unable to send message : ${data.response.data.message}`;
        },
      },
    });

    setName("");
    setPhone("");
    setEmail("");
    setBudget("");
    setMessage("");
    
  };

  return (
    <section
      id="contact-us"
      className="relative flex w-full items-center justify-center px-3 md:px-0"
    >
      <div className="my-16 flex w-full flex-col items-center justify-center md:w-3/4 md:flex-row" id="contact-us-custom-plan">
        <div className="flex flex-1 flex-col gap-[3rem] pr-20 font-sans">
          <h2 id="contactus" className="text-[2rem] font-bold">
            Ready to unlock the full potential of crypto investing?
          </h2>
          <h4 className="font-regular text-[1.35rem]">
            Schedule a demo and discovery call with us to:
          </h4>
          <ul className="flex flex-col gap-[1.7rem] text-[16px]">
            <div className="flex items-center gap-3">
              <FaArrowRightLong className="w-[28px] text-red-400" />
              <li>Learn how Titan Technologies works</li>
            </div>
            <div className="flex items-center gap-3">
              <FaArrowRightLong className="w-[28px] text-red-400" />
              <li>
                Discover how our platform can help you invest more effectively,
                efficiently, and with lower risk
              </li>
            </div>
            <div className="flex items-center gap-3">
              <FaArrowRightLong className="w-[28px] text-red-400" />
              <li>
                See how we stand out from traditional investment firms and other
                crypto platforms
              </li>
            </div>
            <div className="flex items-center gap-3">
              <FaArrowRightLong className="w-[28px] text-red-400" />
              <li>
                Find the suitable plan that best suits your investment goals
              </li>
            </div>
          </ul>
        </div>
        <div className="z-30 my-8 flex h-[100%] w-full flex-1 flex-col gap-[44px] rounded-xl border-[1.1px] border-[#71717133]/20 bg-[#1919194D]/30 p-8 xl:p-[4rem]">
          <h2 className="text-[2rem] font-bold">CONTACT US!</h2>
          <input
            type="text"
            required
            name=""
            placeholder="Name*"
            className="w-full border-b-[1px] border-[#ACACAC] bg-[#00000000] px-1 placeholder-[#ACACAC] outline-none"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <input
            type="tel"
            placeholder="Business Phone*"
            className="w-full border-b-[1px] border-[#ACACAC] bg-[#00000000] px-1 placeholder-[#ACACAC] outline-none"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <input
            type="email"
            name=""
            required
            placeholder="Work Email*"
            className="w-full border-b-[1px] border-[#ACACAC] bg-[#00000000] px-1 placeholder-[#ACACAC] outline-none"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          {/* <input
            type="text"
            name=""
            required
            placeholder="What is your Choice of Plan*"
            className="w-full border-b-[1px] border-[#ACACAC] bg-[#00000000] px-1 placeholder-[#ACACAC] outline-none"
            onChange={(e) => setBudget(e.target.value)}
            value={budget}
          /> */}

<select
  className="w-full border-b-[1px] border-[#ACACAC] bg-[#00000000] px-1 placeholder-[#ACACAC] outline-none"
  onChange={(e) => setBudget(e.target.value)}
  value={budget}
>
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000 !important' }}
    value=""
  >
    What is your Choice of Plan*
  </option>
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000' }} 
    value="Steady Growth Plan"
  >
    Steady Growth Plan
  </option>
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000' }} 
    value="Enhanced Yield Plan"
  >
  Enhanced Yield Plan
  </option>
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000' }} 
    value="Quarterly High-Roller Plan"
  >
    Quarterly High-Roller Plan
  </option>
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000' }} 
    value="Long Term Capital Growth Plan"
  >
    Long Term Capital Growth Plan
  </option>
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000' }} 
    value="Elite Investor Plan"
  >
    Elite Investor Plan
  </option> 
  <option 
    style={{ color: '#ACACAC', backgroundColor: '#00000000' }} 
    value="Custom Plan"
  >
    Custom Plan
  </option> 
</select>


          <textarea
            type="text-area"
            name=""
            required
            placeholder="Message*"
            className="w-full border-b-[1px] border-[#ACACAC] bg-[#00000000] px-1 placeholder-[#ACACAC] outline-none"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <button className="bg-gd w-full rounded-md p-3" onClick={handleMail}>
            Schedule a Meeting
          </button>
        </div>
      </div>
    </section>
  );
}
