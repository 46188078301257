import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar/index.jsx";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import InputField from "components/fields/InputField";
import axios from "axios";
import Banner from "views/admin/marketplace/components/Banner";
import { toast } from "react-toastify";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Profit Booking");
  const [user, setUser] = useState([]);
  const { id } = props;

  const [cryptoData, setCryptoData] = useState(null);
  const [investment, setInvestment] = useState({});
  const [profitBooking, setProfitBooking] = useState({
    coinname: "",
    coincode: "",
    investamount: "",
    investdate: "",
    coinquantity: "",
    coinprice: "",
    currentcoinprice: "",
    sellingcoinquantity: "", // New field
    profit: "0",
    profitpercent: "0",
    uid: "",
  });

  useEffect(() => {
    

    Promise.all([
      axios.get(process.env.REACT_APP_BASE_URL + "/user/getuser", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      axios.get(
        process.env.REACT_APP_BASE_URL + "/investment/getcurrentinvestment",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ),
    ]).then(([userResponse, currInvestRes]) => {
      const userData = userResponse.data;
      const currInvestData = currInvestRes.data;
     
      currInvestData.map((data) => {
        if (data._id === id) {
          setInvestment(data);
          const user_data = userData.filter((item) => item._id === data.uid);
          setUser(user_data[0]);
         
        }
      });
    });
  }, []);

  const handleProfitBooking = () => {
    const currentCoinPrice = parseFloat(profitBooking.currentcoinprice);
    const investedCoinPrice = parseFloat(investment.coinprice);
    const coinQuantity = parseFloat(investment.coinquantity);
    const sellingCoinQuantity = parseFloat(profitBooking.sellingcoinquantity);

    // Check if selling coin quantity is greater than coin quantity
    if (sellingCoinQuantity > coinQuantity) {
      toast("You cannot sell more coins than you have received.");
      return;
    }

    if (
      isNaN(currentCoinPrice) ||
      isNaN(investedCoinPrice) ||
      isNaN(coinQuantity) ||
      isNaN(sellingCoinQuantity)
    ) {
      setProfitBooking({
        ...profitBooking,
        profit: "0",
        profitpercent: "0",
      });
      return;
    }

    const profit = (currentCoinPrice - investedCoinPrice) * sellingCoinQuantity;
    const profitPercent = (
      (profit / (investedCoinPrice * sellingCoinQuantity)) *
      100
    ).toFixed(2);

    setProfitBooking({
      ...profitBooking,
      profit: profit.toFixed(2),
      profitpercent: profitPercent,
    });
  };

  useEffect(() => {
    handleProfitBooking();
  }, [profitBooking.currentcoinprice, profitBooking.sellingcoinquantity]); // Updated dependency array

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const submitprofitbooking = () => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL +
          `/investment/addprofitbook/${investment._id}`,
        {
          coinname: investment.coinname,
          coincode: investment.coincode,
          investamount: investment.investamount,
          investdate: investment.investdate,
          coinquantity: investment.coinquantity,
          coinpriceaftersell: profitBooking.currentcoinprice,
          coinpricebeforesell: investment.coinprice,
          currentcoinprice: profitBooking.currentcoinprice,
          sellingcoinquantity: profitBooking.sellingcoinquantity,
          profit: profitBooking.profit,
          profitpercent: profitBooking.profitpercent,
          uid: investment.uid,
          profitdate: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {

        if (res.status === 200) {
          toast.success("Profit booking successful");
        } else {
          toast("Profit booking failed");
        }
      });
  };

  return (
    <div class="mt-10 px-7">
      <div class="mt-7 grid w-full grid-cols-1 gap-7 md:grid-cols-1 lg:grid-cols-3">
        <div>
          <p class="text-white-800  text-base font-medium leading-none">
            Coin Name
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            value={`${investment.coinname}`}
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 text-base font-medium leading-none">
            Coin Code
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            value={`${investment.coincode}`}
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 mb-3  text-base font-medium leading-none">
            Invested Amount
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 w-full rounded border py-4 outline-none"
            type="number"
            value={investment.investamount}
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 mb-3 text-base font-medium leading-none">
            Investment Date
          </p>
          <InputField
            class="border-white-300  focus:bg-white-50 mt-4 w-full rounded border outline-none"
            value={
              investment.investdate
                ? new Date(investment.investdate).toLocaleDateString()
                : ""
            }
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 mb-3 text-base font-medium leading-none">
            Investment Type
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            value="SELL"
            disabled
          />
        </div>
        <div>
          <p class="text-white-800  mb-3 text-base font-medium leading-none">
            No. of coins received
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            type="text"
            value={investment.coinquantity}
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 text-base font-medium leading-none">
            Coin Price at Investment
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            type="number"
            value={investment.coinprice}
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 text-base font-medium leading-none">
            Current Coin Price
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            type="number"
            value={profitBooking.currentcoinprice}
            onChange={(e) =>
              setProfitBooking({
                ...profitBooking,
                currentcoinprice: e.target.value,
              })
            }
          />
        </div>
        <div>
          <p class="text-white-800  mb-3 text-base font-medium leading-none">
            No. of coins selling
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            type="text"
            value={profitBooking.sellingcoinquantity}
            onChange={(e) =>
              setProfitBooking({
                ...profitBooking,
                sellingcoinquantity: e.target.value,
              })
            }
          />
        </div>
        <div>
          <p class="text-white-800 text-base font-medium leading-none">
            Profit
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            value={
              profitBooking.profit >= 0
                ? profitBooking.profit
                : `-${Math.abs(profitBooking.profit)}`
            }
            disabled
          />
        </div>
        <div>
          <p class="text-white-800 text-base font-medium leading-none">
            Profit Percentage
          </p>
          <InputField
            class="border-white-300 focus:bg-white-50 mt-4 w-full rounded border p-3 outline-none"
            value={
              profitBooking.profitpercent >= 0
                ? profitBooking.profitpercent
                : `-${Math.abs(profitBooking.profitpercent)}`
            }
            disabled
          />
        </div>
      </div>
      <div class="mt-7 flex justify-center">
        <button
          className=" bg-gd mr-1 mb-1 rounded px-6 py-3 text-sm  font-bold text-white outline-none transition-all duration-150 ease-linear focus:outline-none"
          onClick={() => submitprofitbooking()}
        >
          Booked Profit
        </button>
      </div>
    </div>
  );
}
