import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Input from "../../../../../components/fields/InputField";
import { familiarDate, rupeeWrapper } from "utils/utility";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { MdDelete } from "react-icons/md";

export function SlotSheet({ userId, isAdmin = false }) {
  const [slots, setSlots] = useState([]);
  const [editingCell, setEditingCell] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const fetchSlotSheets = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/slotsheet/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setSlots(response.data);
      } catch (error) {
        console.error("Error fetching slot sheets:", error);
      }
    };

    fetchSlotSheets();
  }, [userId]);

  const addSlot = () => {
    const newSlot = {
      // _id: `new-slot-${Date.now()}`, // Temporary unique ID
      name: `SLOT ${slots.length + 1}`,
      entries: [],
    };
    setSlots([...slots, newSlot]);
    setHasChanges(true);
  };

  const addEntry = (slotName) => {
    setSlots((prevSlots) =>
      prevSlots.map((slot) => {
        if (slot.name === slotName) {
          const newEntry = {
            // _id: `new-entry-${Date.now()}`, // Temporary unique ID
            amount: 0,
            referenceNumber: "",
            depositDate: "",
            payoutDate: "",
            payoutAmount: 0,
          };
          return {
            ...slot,
            entries: [...slot.entries, newEntry],
          };
        }
        return slot;
      })
    );
    setHasChanges(true);
  };

  const deleteEntry = (slotId, entryIndex) => {
    const updatedSlots = slots
      .map((slot) => {
        if (slot._id === slotId) {
          const filteredEntries = slot.entries.filter(
            (_, index) => index !== entryIndex
          );
          return filteredEntries.length === 0
            ? null
            : {
                ...slot,
                entries: filteredEntries,
              };
        }
        return slot;
      })
      .filter(Boolean); // Remove null values

    setSlots(updatedSlots);
    setHasChanges(true);
  };

  const updateCellValue = (value, slotId, entryId, field) => {
    setSlots((prevSlots) =>
      prevSlots.map((slot) => {
        if (slot._id === slotId) {
          return {
            ...slot,
            entries: slot.entries.map((entry) => {
              if (entry._id === entryId) {
                return {
                  ...entry,
                  [field]: value,
                };
              }
              return entry;
            }),
          };
        }
        return slot;
      })
    );
    setHasChanges(true);
  };

  const renderCell = (entry, field, slot, index) => {
    const isEditing =
      editingCell?.slotId === slot._id &&
      editingCell?.entryId === entry._id &&
      editingCell?.field === field;

    const fieldConfig = {
      amount: { type: "number", format: (val) => rupeeWrapper(val || 0) },
      referenceNumber: { type: "text", format: (val) => val || "" },
      depositDate: {
        type: "date",
        format: (val) => (val ? familiarDate(val) : "N/A"),
      },
      payoutDate: { type: "text", format: (val) => val || "" },
      payoutAmount: { type: "number", format: (val) => rupeeWrapper(val || 0) },
    };

    const config = fieldConfig[field];
    const isPayoutField = field === "payoutAmount" || field === "payoutDate";

    if (isAdmin && isEditing) {
      return (
        <Input
          type={config.type}
          value={entry[field]?.toString() || ""}
          onChange={(e) =>
            updateCellValue(e.target.value, slot._id, entry._id, field)
          }
          onBlur={() => setEditingCell(null)}
          autoFocus
          className="w-full border-gray-600 bg-gray-700 text-white"
        />
      );
    }

    return (
      <span
        className={`block ${
          isPayoutField ? "bg-[#1e1e1e]" : ""
        } w-full cursor-pointer rounded px-3 py-2 transition-all duration-150 hover:bg-[#353535] ${
          !entry[field] ? "italic text-gray-500" : ""
        }`}
        onClick={() =>
          isAdmin &&
          setEditingCell({
            slotId: slot._id,
            entryId: entry._id,
            field,
          })
        }
      >
        {config.format(entry[field])}
      </span>
    );
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    const tableData = slots.flatMap((slot) => [
      [{ content: slot.name, colSpan: 6, styles: { fontStyle: "bold" } }],
      ...slot.entries.map((entry, index) => [
        index + 1,
        entry.amount,
        entry.referenceNumber,
        entry.depositDate ? familiarDate(entry.depositDate) : "-",
        entry.payoutDate ? familiarDate(entry.payoutDate) : "-",
        entry.payoutAmount,
      ]),
    ]);

    doc.autoTable({
      head: [
        [
          "Column",
          "Amount",
          "Reference Number",
          "Deposit Date",
          "Payout Date",
          "Payout Amount",
        ],
      ],
      body: tableData,
      theme: "grid",
    });

    doc.save("slot-sheet.pdf");
  };

  const updateBackend = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/updateSheet/${userId}`,
        { slots },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Sheet updated successfully!");
      setHasChanges(false);
    } catch (error) {
      console.error("Error updating slots:", error);
      toast.error("Failed to update sheet");
    }
  };

  const fields = [
    "amount",
    "referenceNumber",
    "depositDate",
    "payoutDate",
    "payoutAmount",
  ];

  return (
    <div className="dark min-h-[20vh] p-4 text-white">
      <div className="mb-4 flex flex-wrap items-center justify-between gap-2">
        <h2 className="text-xl font-bold md:text-2xl">Slot Sheet</h2>
        <div className="space-x-2">
          {isAdmin && (
            <>
              <button
                className="bg-gd rounded px-3 py-2 text-sm md:text-base"
                onClick={addSlot}
              >
                Add Slot
              </button>
              <button
                className="rounded bg-[#222222] px-3 py-2 text-sm md:text-base"
                onClick={updateBackend}
                disabled={!hasChanges}
              >
                Update Changes
              </button>
            </>
          )}
          {slots.length > 0 && (
            <button
              className="rounded bg-blue-600 px-3 py-2 text-sm md:text-base"
              onClick={exportToPDF}
            >
              Export PDF
            </button>
          )}
        </div>
      </div>

      <div className="border-1 overflow-x-auto rounded-lg border !border-[#3e3e3e]">
        <table className="w-full text-sm md:text-base">
          <thead>
            <tr className="bg-[#222222]">
              <th className="border-b border-r border-[#3e3e3e] p-2 text-left">
                Column
              </th>
              {fields.map((field) => (
                <th
                  key={field}
                  className="border-b border-r border-[#3e3e3e] p-2 text-left"
                >
                  {field.charAt(0).toUpperCase() +
                    field.slice(1).replace(/([A-Z])/g, " $1")}
                </th>
              ))}
              {isAdmin && (
                <th className="border-b border-r border-[#3e3e3e] p-2 text-left">
                  Delete Entry
                </th>
              )}
            </tr>
          </thead>

          <tbody>
            {slots.length === 0 && (
              <tr className="bg-gd50 h-40 text-center">
                <td
                  colSpan={isAdmin ? 7 : 6}
                  className="border-b border-r border-[#3e3e3e] p-2 text-sm font-bold md:text-base"
                >
                  No slots
                </td>
              </tr>
            )}
            {slots.map((slot) => (
              <React.Fragment key={slot._id}>
                <tr className="bg-gd">
                  <td
                    colSpan={isAdmin ? 7 : 6}
                    className="border-b border-r border-[#3e3e3e] p-2 text-sm font-bold md:text-base"
                  >
                    {slot.name}
                  </td>
                </tr>
                {slot.entries.map((entry, index) => (
                  <tr
                    key={entry._id}
                    className="border-b border-r border-[#3e3e3e]"
                  >
                    <td className="p-2">{index + 1}</td>
                    {fields.map((field) => (
                      <td
                        key={`${entry._id}-${field}`}
                        className="border-x border-[#3e3e3e]"
                      >
                        {renderCell(entry, field, slot, index)}
                      </td>
                    ))}
                    {isAdmin && (
                      <td>
                        <div
                          onClick={() => deleteEntry(slot._id, index)}
                          className="mx-auto my-1 flex w-fit cursor-pointer rounded bg-red-400 p-1.5 text-[24px] text-[#000000]"
                        >
                          <MdDelete />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
                {isAdmin && (
                  <tr>
                    <td colSpan={7} className="p-2">
                      <button
                        className="w-full rounded py-2 text-sm text-gray-300 transition hover:bg-[#2f2f2f] md:text-base"
                        onClick={() => addEntry(slot.name)}
                      >
                        Add Entry
                      </button>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SlotSheet;
